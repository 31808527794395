#HomeSlidesWrapper {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--white);
  width: 100%;
  user-select: none;
}

#homeSlidesCarousel {
  display: block;
}

ul.control-dots {
  position: relative;
  top: 0;
  transform: translatey(98%);
}

li.dot {
  height: 5px;
}

.slideWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeSlideImg {
  height: 33svh;
  max-height: 350px;
  object-fit: cover;
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 30%
  );
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 30%
  );
}

.slideSecondBox {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-top: 5%;
  min-height: 400px;
  margin-top: 0;
  width: 100vw;
}

.homeSlideTitle {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
  width: 70%;
  color: var(--green);
  margin: 22px 0 0;
  letter-spacing: 2.5px;
}

.homeSlideText {
  display: flex;
  text-transform: none;
  padding: 5% 10vw;
  font-weight: 300;
  font-size: 14px;
}

.homeSlideBtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  left: 50%; /* Center the button horizontally */
  transform: translateX(-50%); /* Move the button back by half of its width */
  height: 50px;
  width: 160px;
  margin-top: 50px;
  border: 2px solid var(--green);
  border-radius: 5px;
  background-color: transparent;
  transition: all ease 0.2s;
  font-size: 12px;
  padding: 5px;
  text-decoration: none;
  color: var(--black);
}

.homeSlideBtn:hover {
  background-color: var(--green);
  color: var(--white);
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .homeSlideImg {
    height: 50vw;
  }
}

@media only screen and (min-width: 992px) {
  /* .slide{
        height: 750px; 
         background-color: var(--white);
    } */

  #HomeSlidesWrapper {
    height: 500px;
    /* max-height: 500px; */
    padding: 0 10vw;
    width: 100vw;
    min-height: 300px;
  }

  #homeSlidesCarousel {
    height: 550px;
  }

  .hslideWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 500px;
    max-height: 500px;
    min-height: 500px;
    width: 80vw;
    background-color: var(--white);
  }

  .homeSlideImg {
    display: block;
    height: 100%;
    object-fit: cover;
    max-height: 500px;
    mask-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 0%
    );
    -webkit-mask-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.9) 0%
    );
  }

  .imgGradiant {
    position: relative;
    height: 500px;
    width: 100vw;
  }

  .slideSecondBox {
    display: flex;
    position: relative;
    width: 80vw;
    padding: 0 5vw;
    background-color: var(--white);
    transform: translateY(0px);
    margin-top: auto;
    margin-bottom: auto;
  }
  .homeSlideTitle {
    position: relative;
    left: 0;
    padding: 0;
    transform: none;
    font-size: 250%;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }

  .homeSlideText {
    position: relative;
    text-align: left;
    padding: 30px 0 0px 0;

    max-width: 90%;
    min-width: 100px;
  }

  .homeSlideBtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: unset;
    left: 50%; /* Center the button horizontally */
    transform: none; /* Move the button back by half of its width */
    margin-top: 30px;
  }

  ul.control-dots {
    transform: translatey(460px);
    margin-bottom: 50px;
    height: 15px;
  }
}
