#homeHeroWrapper {
  display: none;
}

@media only screen and (min-width: 992px) {
  #homeHeroWrapper {
    display: block;
    padding: 0 50px;
    height: 400px;
    background-color: transparent;
    margin: 0 100px;
    position: relative;
  }

  #homeHeroLogo {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
    height: 23vw;
    max-height: 400px;
    min-height: 400px;
    padding: 65px;
    width: 100%;
    max-width: 80vw;
    /* margin-top: 50px; */
    z-index: 2;
    background: linear-gradient(
      to right,
      rgba(254, 254, 254, 1) 0%,
      rgba(254, 254, 254, 0) 5%,
      rgba(254, 254, 254, 0.9) 30%,
      rgba(254, 254, 254, 0.9),
      rgba(254, 254, 254, 0.9) 70%,
      rgba(254, 254, 254, 0) 95%,
      rgba(254, 254, 254, 1) 100%
    ); /* Adjust alpha values for transparency */
  }
}
