.PresWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PresTopper{
    height: 25vw;
    max-height: 300px;
    min-height: 150px;
    width: 100%;
    object-fit: cover;
}

.PresTopperTop10{
    object-position: 100% 10%;
}

.PresTopperTop{
    object-position: 100% 30%;
}
.PresTopperMid{
    object-position: 100% 50%;
}
.PresTopperBottom{
    object-position: 100% 70%;
}

.PresTitle{
    font-size: 30px;
    font-weight: 200;
    border-bottom: 3px solid var(--green);
    line-height: 40px;
    margin: 30px;
    text-align: center;
}

.Pres span{
    font-family: 'Dongle', sans-serif;
    font-size: 32px;
    line-height: 1.5rem;
    color: var(--green);
}

.PresAtText{
    line-height: 2rem;
    letter-spacing: 1px;
    padding: 10vw;
    white-space: no-wrap;
}

.Pres p{
    text-transform: none;
    text-align: center;
    padding: 0 10vw;
    margin: 0 0px;
}

.PresSeparateur{
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    margin: 30px;
}

.PresSepLines{
    width: 70vw;
    max-height: 2px;
}

.PresSepLogo{
    position: absolute;
}

.PresExplain p{
    text-transform: none;
    text-align: left;
    padding: 0 10vw;
    line-height: 2rem;
    letter-spacing: 1px;
    margin: 50px 0;
}

.TitleWrapperContact{
    display: flex;
    flex-direction: row;
    background-color: var(--white);
    margin-left: auto;
    margin-right: auto;
}

.TitleWrapperContact p{
    padding: 7.5px  0 0 15% ;
    text-align: center;
    height: 50px;
    background-color: var(--white);
    z-index: 2;
}

@media only screen and (min-width: 992px){
    .PresTitle{
        margin: 50px;
    }

    .Pres{
        margin-bottom: 30px;
    }

    .Pres, .PresExplain{
        width: 60%;
        max-width: 900px ;
    }
    .PresExplain{
        width: 80%;
        max-width: 900px ;
    }

}
