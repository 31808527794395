#notFoundWrapper {
  width: 100%;
  height: 100%;
}

#nf_background {
  background-image: url("./../assets/notFound/nf_background.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center;
}

#nf_logo {
  background-image: url("./../assets/notFound/nf_logo.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: -28vh;
  background-position: center;
}
#nf_jar {
  background-image: url("./../assets/notFound/nf_jar.svg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 150px;
  position: fixed;
  bottom: -175px;
  background-position: center;
}

.oups {
  position: absolute;
  top: -30px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
  padding-left: 50%;
  font-size: 2.5rem;
  font-weight: 200;
  line-height: 110%;
  text-align: center;
}

.nfBack {
  position: absolute;
  color: black;
  bottom: 100px;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  #nf_jar {
    display: flex;
    width: 50%;
    height: 50%;
    background-size: 250px;
    bottom: unset;
    background-position: 50%;
    margin-left: 10%;
  }

  .oups {
    position: absolute;
    top: 0px;
    left: 50vw;
    right: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
    padding-left: 5%;
    font-size: 3.5rem;
    font-weight: 200;
    line-height: 170%;
    text-align: left;
  }

  .nfContentWrapper {
    padding-top: 30vh;
  }
  .jarWrapper {
    width: 500px;
  }
  .nfContentWrapper div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }
  .nfBack {
    position: relative;
    color: black;
    bottom: 0;
    top: 35vh;
    display: flex;
    justify-content: center;
    align-content: center;
    width: unset;
    margin-left: 19%;
  }
}
