.boitesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.Title {
  font-size: 20px;
  font-weight: 300;
  padding: 0 10px;
  background-color: var(--white);
  z-index: 2;
  min-width: 150px;
}

.Logo {
  font-size: 20px;
  font-weight: 300;
  padding: 0 10px;
  background-color: var(--white);
  z-index: 2;
  transform: translateY(20%);
}

.liner {
  border: 0.5px solid var(--black);
  width: 90vw;
  position: relative;
  top: -15px;
  max-width: 1000px;
}

.boite {
  position: relative;
  border: 2px solid var(--black);
  width: 80vw;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  max-width: 450px;
}

.boiteAtelier {
  position: relative;
  border: 2px solid var(--black);
  width: 80vw;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  max-width: 450px;
}

.boiteCategorie {
  font-size: 18px;
  font-weight: 300;
  min-height: 50px;
  margin: 30px 0;
  width: 70%;
  text-align: center;
}

.boiteCategorieAtelier {
  font-size: 18px;
  font-weight: 400;
  margin: 30px 0;
  width: 70%;
  text-align: center;
}

.atelierImgTop {
  position: relative;
  width: 90%;
  min-height: 150px;
  max-height: 200px;
  height: 30vw;
  object-fit: cover;
  margin-bottom: 25px;
}

.boiteUl {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.boiteUlAtelier {
  margin-bottom: 50px;
  margin-left: 10%;
  margin-right: auto;
}

.boiteUl li {
  list-style: none;
  margin: 10px 0;
  display: flex;
  text-transform: none;
}

.boiteUlAtelier div {
  list-style: none;
  margin: 0 25px 0;
  text-transform: none;
}

.boiteUlAtelier div:first-child {
  list-style: none;
  margin: 25px 25px 0;
  text-transform: none;
}

.boiteUlAtelier li {
  list-style: none;
  margin: 20px 0;
  display: flex;
  text-transform: none;
}

.boiteUlAtelier div li:first-child {
  list-style: none;
  margin: 0px 0px 20px;
  display: flex;
  text-transform: none;
}

.cornerTop {
  position: absolute;
  left: 0;
}

.cornerTopAtelier {
  position: absolute;
  left: 0;
}

.cornerBottom {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateX(0.4px) translateY(0.4px);
}

.cornerBottomAtelier {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateX(0.4px) translateY(0.4px);
}

.boiteUl li img {
  height: 20px;
  margin-top: 2.5px;
  margin-right: 10px;
}

.boiteUlAtelier li img {
  height: 20px;
  margin-top: 2.5px;
  margin-right: 10px;
}

.traiteurButtonsWrapper {
  display: flex;
  flex-direction: column;
}

.traiteurButtonsWrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  height: 50px;
  width: 200px;
  border: 2px solid var(--green);
  border-radius: 5px;
  background-color: transparent;
  transition: all ease 0.2s;
  font-size: 14px;
  padding: 25px;
  margin-bottom: 25px;
  text-decoration: none;
  color: var(--black);
  text-align: center;
}

.traiteurButtonsWrapper a:hover {
  background-color: var(--green);
  color: var(--white);
  cursor: pointer;
}

.atelierLink {
  position: absolute;
  bottom: 25px;
  right: 10%;
  text-transform: none;
  text-decoration: underline;
  color: var(--black);
  transition: all ease 0.2s;
}

.atelierLink:hover {
  color: var(--green);
}

.atelierSocialInvite {
  display: inline;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: none;
  line-height: 2rem;
  max-width: 400px;
  margin: 25px 0 50px;
  font-size: 16px;
}

.atelierSocialInvite a {
  color: var(--black);
  text-transform: none;
  transition: all ease 0.2s;
  font-size: 16px;
}

.atelierSocialInvite a:hover {
  color: var(--green);
  text-transform: none;
}

@media only screen and (min-width: 992px) {
  .boitesList {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: center;
    margin-top: 50px;
  }

  .atelierImgTop {
    height: 200px;
    width: 200px;
    position: relative;
    border-radius: 1px;
    top: 0;
    left: 25px;
    margin: 0;
    aspect-ratio: 1/1;
  }

  .boiteCategorieAtelier {
    position: absolute;
    top: 0;
    margin: 20px 0;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .boitesListAtelier {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }

  .boite {
    width: 30vw;
    min-width: 200px;
    max-width: 300px;
  }

  .boiteAtelier {
    width: 90vw;
    display: flex;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    min-width: none;
    max-width: 1000px;

    flex-direction: row;
  }

  .boiteUlAtelier {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    width: 100%;
    height: 100px;
    margin-left: 75px;
  }

  .boiteUlAtelier div {
    list-style: none;
    margin: 10px 25px;
    text-transform: none;
  }

  .boiteUlAtelier div:first-child {
    list-style: none;
    margin: 10px 25px 0;
    text-transform: none;
  }

  .boiteUlAtelier div li {
    list-style: none;
    margin: 15px 0;
    display: flex;
    text-transform: none;
    min-width: 200px;
  }

  .cornerTopAtelier {
    position: absolute;
    top: 0;
  }

  .traiteurButtonsWrapper {
    flex-direction: row;
  }

  .traiteurButtonsWrapper a {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 50px;
    height: 50px;
    width: 200px;
    border: 2px solid var(--green);
    border-radius: 5px;
    background-color: transparent;
    transition: all ease 0.2s;
    font-size: 14px;
    padding: 25px;
    margin: 25px;
  }

  .atelierLink {
    right: 7.5%;
  }
}
