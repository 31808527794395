.aboutTop,
.aboutBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10vw;
}

.strucMarginTop {
  margin-top: 50px;
}

.aboutTop {
  margin-top: 50px;
}

.aboutBottom {
  margin-bottom: 50px;
}

.aboutTop p,
.aboutBottom p {
  text-transform: none;
  line-height: 2rem;
  letter-spacing: 1px;
}

.aboutBottom span {
  color: var(--green);
  text-transform: none;
}

.aboutCitation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0 0;
}

.aboutCitation p,
.aboutCitation p span {
  font-size: 40px;
  font-family: "Dongle";
  letter-spacing: 0.2rem;
  line-height: 4rem;
  text-transform: uppercase;
}

.aboutCitation p span {
  color: var(--green);
}

.aboutTop img,
.aboutBottom img {
  width: 100vw;
  height: 40vh;
  min-height: 300px;
  max-height: 350px;
  object-fit: cover;
  object-position: center;
  margin: 50px 0;
}

.aboutTop img,
.aboutBottom img {
  object-position: center 30%;
}

.aboutDesktop {
  display: none;
}

@media only screen and (min-width: 992px) {
  .aboutMobile {
    display: none;
  }
  .aboutDesktop {
    display: inline;
  }

  .aboutTop,
  .aboutBottom {
    flex-direction: row;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    align-items: start;
  }

  .aboutTop img,
  .aboutBottom img {
    width: 50vw;
    height: 850px;
    max-height: 850px;
    max-width: 450px;
    padding: 50px 25px 25px 0px;
    margin: auto 0 50px;
  }

  .aboutTop p {
    padding: 50px 50px 0 0px;
    margin: 0 50px 0 25px;
  }

  .aboutBottom div {
    display: flex;
    flex-direction: column;
    padding: 50px 10px 0 50px;
    margin: 0 25px 0 50px;
  }

  .aboutBottom div p:first-child {
    margin-bottom: 50px;
  }

  .aboutBottom img {
    margin-left: 25px;
  }

  .aboutCitation {
    margin-top: 25px;
  }
}
