.contactFindWrapper {
  display: flex;
  flex-direction: column-reverse;
  margin: 50px auto 50px auto;
  border: 1.5px solid var(--black);
  max-width: 920px;
  width: 80vw;
}

.contactFindInfos {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.contactFindAdress {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 35px;
  text-align: center;
}

.contactFindAdress a {
  color: var(--black);
  transition: all ease 0.2s;
}
.contactFindAdress a:hover {
  color: var(--green);
}
.contactFindLogos {
  display: flex;
  justify-content: space-around;
  align-content: center;
  margin: 25px;
}

.contactFindLogos img:first-child {
  width: 75px;
}

.contactFindLogos img:nth-child(2) {
  width: 120px;
}

.contactFindLiner {
  height: 1.5px;
  background-color: var(--black);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.contactFindMap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.contactFindMap iframe {
  width: 100%;
  height: 250px;
}

@media only screen and (min-width: 750px) {
  .contactFindWrapper {
    flex-direction: row;
  }

  .contactFindMap iframe {
    height: 100%;
  }
}
