.CLClientsBG {
  background-color: var(--white);
  height: 175px;

  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.CLSLidesClients {
  height: 175px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CLSLidesClients img {
  max-width: auto;
  height: 100px;
  padding: 0 5px;
}

.black {
  height: 50px;
  /* background-color: var(--black); */
}
