#footerFFWrapper {
  background-color: var(--white);
  color: var(--black);
}

#footerFFS1 a,
#footerFFS2 a,
#footerFFS3 a {
  color: var(--black);
  transition: all ease 0.2s;
}

#footerFFS1 a:hover,
#footerFFS2 a:hover,
#footerFFS3 a:hover {
  color: var(--green);
}

#footerFFS1,
#footerFFS2,
#footerFFS3 {
  display: flex;
  flex-direction: row;

  align-items: center;
  position: relative;
  padding: 45px 15vw;
  padding-left: 20vw;
  width: 100%;
}

#footerFFS1 {
  padding-top: 50px;
}

#footerFFS1 img,
#footerFFS2 img {
  margin-right: 55px;
}

#footerFFS3 img {
  height: 146px;
  transform: translateX(-20%);
}

#footerAdress {
  margin-left: 5vw;
}
#footerFFS3 {
  display: flex;
  justify-content: center;
}
.footerSeparator {
  position: relative;
  left: 0;
  transform: translateX(17%);
  width: 75%;
  background-color: var(--black);
  height: 1px;
}

.footerFFCorp {
  font-family: "Dongle", sans-serif;
  font-size: 32px;
  line-height: 1.5rem;
  color: var(--green);
}

@media only screen and (min-width: 780px) {
  #footerFFWrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  #footerFFS1,
  #footerFFS2,
  #footerFFS3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 25px;
    width: 100%;
  }

  #footerFFS3 {
    width: 160%;
  }

  #footerFFS1 p {
    max-width: 100px;
  }

  #footerFFS2 p {
    max-width: 120px;
  }

  #footerFFS1 img,
  #footerFFS2 img {
    margin-right: 35px;
  }

  #footerAdress {
    margin-left: 50px;
  }

  .footerSeparator {
    position: relative;
    left: auto;
    top: 0;
    transform: translateY(none);
    margin-top: 25px;
    width: 5px;
    background-color: var(--black);
    height: 150px;
  }
}
