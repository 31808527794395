#sendButton:disabled {
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}

.required:after {
  content: " *";
  color: var(--green);
}

.contactWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contatFormHeader {
  text-align: center;
  margin: 75px 0 25px;
  max-width: 70%;
}

.contatFormHeader p {
  padding: 5px 0;
}

.contactFormFields,
.contactFormMessage {
  display: flex;
  flex-direction: column;
  text-transform: none;
}

.contactFormFields input,
.contactFormFields select,
textarea {
  height: 40px;
  border: 2px solid var(--black);
  width: 75vw;
  padding: 0 10px;
  margin: 5px 0 20px;
  max-width: 450px;
  text-transform: none;
}

textarea {
  resize: none;
  height: 385px;
  text-transform: none;
  color: var(--black);
  padding: 10px;
}

#miss {
  display: none;
}

option {
  font-size: 14px;
  text-transform: none;
  max-width: 75px;
}

.contactButton button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  left: 50%; /* Center the button horizontally */
  transform: translateX(-50%); /* Move the button back by half of its width */
  height: 50px;
  width: 160px;
  margin-top: 4.5vh;
  margin-bottom: 50px;
  border: 2px solid var(--green);
  border-radius: 5px;
  background-color: transparent;
  transition: all ease 0.2s;
}

.contactButton button:hover {
  background-color: var(--green);
  color: var(--white);
  cursor: pointer;
}

@media only screen and (min-width: 992px) {
  .contactFormWrapper {
    display: flex;
    width: 80vw;
    max-width: 1000px;
  }

  .formInputWrapper {
    display: flex;

    width: 80vw;
    max-width: 1000px;
    align-content: space-between;
    justify-content: center;
  }

  .contactFormFields input,
  .contactFormFields select,
  textarea {
    width: 30vw;
  }

  #contactForm {
    margin-right: auto;
    margin-left: auto;
  }

  .contactFormFields,
  .contactFormMessage {
    margin-left: auto;
    margin-right: auto;
  }
}
