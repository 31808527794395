#footerCLWrapper {
  background-color: var(--white);
  color: var(--black);
  padding-bottom: 50px;
}

#footerCLS1CL a,
#footerCLS2CL a,
#footerCLS3CL a {
  color: var(--black);
  transition: all ease 0.2s;
}

#footerCLS1CL a:hover,
#footerCLS2CL a:hover,
#footerCLS3CL a:hover {
  color: var(--green);
}

#footerCLS1CL,
#footerCLS2CL,
#footerCLS3CL {
  display: flex;
  flex-direction: row;

  align-items: center;
  position: relative;
  padding: 45px 15vw;
  padding-left: 20vw;
  width: 100%;
}

#footerCLS1CL {
  padding-top: 50px;
}

#footerCLS1CL img,
#footerCLS2CL img {
  margin-right: 55px;
}

#footerCLS3CL img {
  height: 146px;
  transform: translateX(-20%);
}

#footerAdressCL {
  text-align: center;
}
#footerCLS3CL {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  margin-top: 50px;
  margin-bottom: 50px;
}
.footerSeparatorCL {
  position: relative;
  left: 0;
  transform: translateX(17%);
  width: 75%;
  background-color: var(--black);
  height: 1px;
}

.footerCLCorp {
  font-family: "Cookie", sans-serif;
  text-transform: none;
  font-size: 36px;
  line-height: 1.5rem;
  color: var(--Black);
}

@media only screen and (min-width: 780px) {
  #footerCLWrapper {
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 0;
  }

  #footerCLS1CL,
  #footerCLS2CL,
  #footerCLS3CL {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 25px;
    width: 100%;
  }

  #footerCLS3CL {
    width: 100%;
    transform: unset;
    padding-bottom: 0;
    padding-top: 0;
  }

  #footerCLS1CL p {
    max-width: 100px;
  }

  #footerCLS2CL p {
    max-width: 120px;
  }

  #footerCLS1CL img,
  #footerCLS2CL img {
    margin-right: 35px;
  }

  #footerAdressCL {
    margin-left: 50px;
  }

  .footerSeparatorCL {
    position: relative;
    left: auto;
    top: 0;
    transform: translateY(none);
    margin-top: 25px;
    width: 5px;
    background-color: var(--black);
    height: 150px;
  }
}
