.TrClientsWrapper {
  background-color: var(--white);
  margin-bottom: 50px;
  margin-top: 50px;
}

.TrClientsBG {
  background-color: var(--white);
  height: 500px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.TrSLidesClients {
  height: 250px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TrSLidesClients img {
  height: 250px;
  width: 250px;
  padding: 0 0px;
  object-fit: cover;
}
