#CLSlidesWrapper {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--white);
  width: 100%;
}

#CLSlidesCarousel {
  display: block;
}

ul.control-dots {
  position: relative;
  top: 0;
  transform: translatey(95%);
}

li.dot {
  height: 5px;
}

.slideWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CLSlideImg {
  height: 33svh;
  max-height: 350px;
  object-fit: cover;
}

/* .slideSecondBox{
    display: flex;
    flex-direction: column;
    align-content: center;
    padding-top: 10%;
    min-height: 400px;
    max-width: 90vw;
    margin-top: 0;
} */

.CLSlideTitle {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
  width: 50%;
  color: var(--green);
  margin: 22px 0;
  letter-spacing: 2.5px;
}

.CLSlideText {
  text-transform: none;
  padding: 5% 10vw;

  font-weight: 300;
  font-size: 16px;
}

.CLSlideBtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  left: 50%; /* Center the button horizontally */
  transform: translateX(-50%); /* Move the button back by half of its width */
  height: 50px;
  width: 160px;
  margin-top: 50px;
  border: 2px solid var(--green);
  border-radius: 5px;
  background-color: transparent;
  transition: all ease 0.2s;
}

.CLSlideBtn:hover {
  background-color: var(--green);
  color: var(--white);
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .CLSlideImg {
    height: 50vw;
  }
}

@media only screen and (min-width: 992px) {
  .slide {
    /* height: 750px; */
    background-color: transparent;
  }

  #CLSlidesWrapper {
    height: 30vh;
    max-height: 500px;
    padding: 0 10vw;
    width: 100%;

    min-height: 300px;
    /* background-color: var(--black); */
  }

  #CLSlidesCarousel {
    height: 550px;
  }

  .slideWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 550px;
    max-height: 550px;

    min-height: 550px;
    width: 100%;
  }

  .CLSlideImg {
    display: none;
    height: 100%;
    object-fit: cover;
    max-height: 500px;
    max-width: 1000px;
  }

  .imgGradiant {
    position: relative;
    height: 100%;
    width: 100vw;
  }

  /* .slideSecondBox{
        display: flex;
        position: relative;
        width: 100%;
        padding: 0 5vw;
        background-color: var(--white);
        transform: translateY(-25%);
        
        
            } */
  .CLSlideTitle {
    position: relative;
    left: 0;
    padding: 0;
    transform: none;
    font-size: 250%;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }

  .CLSlideText {
    position: relative;
    text-align: left;
    padding: 30px 0 0px 0;

    max-width: 90%;
    min-width: 100px;
  }

  .CLSlideBtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: unset;
    left: 50%; /* Center the button horizontally */
    transform: none; /* Move the button back by half of its width */
    margin-top: 30px;
  }

  ul.control-dots {
    transform: translatey(460px);
    margin-bottom: 50px;
    height: 15px;
  }
}
