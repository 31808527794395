.CLBackground {
  background: linear-gradient(
    360deg,
    rgb(30, 30, 30) 0%,
    rgba(40, 40, 40, 0.93) 50%,
    rgb(30, 30, 30) 100%
  );
}

a.clIncitBtn {
  text-decoration: none;
  display: flex;
}

.clBodyWrapper {
  color: var(--white);
  padding-bottom: 50px;
}
.clIncitatif {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
}
.clIncitatif h3 {
  font-size: 25px;
  text-align: center;
  font-weight: 400;
  letter-spacing: 2px;
  padding: 50px 0;
}

.clIncitatif p {
  text-transform: none;
  line-height: 1.8rem;
  margin-bottom: 25px;
}

.clIncitatif a.clIncitBtn {
  float: inline-end;
  align-items: center;
  height: 50px;
  width: auto;
  max-width: 200px;
  margin-top: 10px;
  margin-bottom: 50px;
  padding: 5px 10px;
  border: 2px solid var(--white);
  color: var(--white);
  border-radius: 5px;
  background-color: transparent;
  transition: all ease 0.2s;
}

a.clIncitBtn:hover {
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;
}

.clDescription {
  margin-top: 50px;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
}

.clDescription p,
.clDescription ul li {
  text-transform: none;
  line-height: 1.8rem;
}

.clDescription ul li:first-child {
  margin-top: 25px;
}

.clDescription p {
  margin-top: 50px;
}

.clDescription ul {
  margin: 0px 50px;
}

.CLDesktop {
  display: none;
}

.CLTitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  background-color: tar;
  margin-top: -1px;
}

.CLTitle {
  font-size: 20px;
  font-weight: 300;
  padding: 0 10px 0 10px;
  text-align: center;
  background-color: #2b2b2b;
  z-index: 2;
  min-width: 150px;
  color: var(--white);
}

.CLLogo {
  font-size: 20px;
  font-weight: 300;
  padding: 0 10px;
  /* background-color: var(--black); */
  z-index: 2;
  transform: translateY(20%);
}

.CLliner {
  border: 0.5px solid var(--white);
  width: 90vw;
  position: relative;
  top: -15px;
  max-width: 1000px;
}

@media only screen and (min-width: 992px) {
  .clIncitatif {
    max-width: 900px;
  }

  .clIncitatif h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 70px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: "Cookie", cursive;
    font-weight: 100;
    text-transform: none;
  }

  .clIncitatif h3 {
    font-size: 35px;
  }

  .clUtilities {
    display: flex;
    justify-content: center;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  .clUtilities p:first-child {
    font-size: 20px;
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 50px;
  }
}
