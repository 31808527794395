:root {
    --white: #fefefe;
    --black: #1e1e1e;
    --green: #2eac47;
  }

  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
}

.loadingFiller{
  height: 120vh;
}
