#homeClubWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  margin-top: 25px;
}

.hideOnMobile {
  display: none;
}
#flex1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.h3Info,
.pInfoSub {
  text-align: center;
  font-weight: 300;
}

.h3Info {
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0.15rem;
}

.pInfoSub {
  margin: 25px 0;
}

.pInfo {
  padding-bottom: 30px;
  text-transform: none;
  font-weight: 300;
  margin-top: 25px;
}

#flex2 img {
  position: relative;
  top: 0;
  width: 100%;
}

.ulInfoClub {
  padding: 0 30px;
}

.liInfoClub {
  display: flex;
  padding-bottom: 18px;
}

.liInfoClub span {
  list-style: none;
  text-transform: none;
  font-weight: 300;
}

.liInfoClub span:first-child {
  margin-right: 10px;
}

.btnClub {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  left: 50%; /* Center the button horizontally */
  transform: translateX(-50%); /* Move the button back by half of its width */
  height: 50px;
  width: 160px;
  margin-top: 4.5vh;
  margin-bottom: 50px;
  border: 2px solid var(--green);
  border-radius: 5px;
  background-color: transparent;
  transition: all ease 0.2s;
  text-decoration: none;
  color: var(--black);
  text-align: center;
  font-size: 12px;
}
.btnClub:hover {
  background-color: var(--green);
  color: var(--white);
  cursor: pointer;
}
@media only screen and (min-width: 992px) {
  #homeClubWrapper {
    margin-top: 0;
  }

  .hideOnMobile {
    display: inline;
    padding: 0;
    position: relative;
  }
  .hideOnDesktop {
    display: none;
  }

  .h3Info {
    margin-top: 75px;
  }
  .h3Info,
  .pInfoSub {
    padding: 0;
    margin-bottom: 0;
  }

  #homeClubWrapper {
    margin-top: 25px;
    flex-direction: row;
    width: 100vw;
    align-items: center;
    justify-content: center;
    max-height: 550px;
    padding: 0 10vw;
  }

  .homeFlexWrapper {
    flex-direction: column;
    position: relative;
    width: 40vw;
    padding: 0 50px;
  }

  #flex2 img {
    position: relative;

    height: 550px;
    width: 40vw;
    padding-left: 3%;
    object-fit: cover;
    transform: translateY(3px);
  }

  .pInfo,
  .ulInfoClub {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    min-width: 400px;
    max-width: 600px;
  }

  .ulInfoClub {
    max-width: 600px;
  }

  .btnClub {
    position: relative;
    left: 80%; /* Center the button horizontally */
    margin: 50px 10px;
  }
}
