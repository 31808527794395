* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}

.CLHiddenDesktop {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 145px;
  object-fit: cover;
  top: 0;
  left: 0;
}

.fontDongle {
  font-family: "Dongle", Arial, Helvetica, sans-serif;
  font-size: 32px;
  line-height: 1.5rem;
}

.fontCookie {
  font-family: "Cookie", cursive;
  font-size: 30px;
  text-transform: none;
}

#mobileNav ul {
  position: relative;
  top: 0svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
}

#mobileNav ul li {
  padding-top: 5vh;
}

#mobileNav ul:first-child {
  margin-top: 0;
}

#headerWrapperCL {
  background-color: var(--black);
  height: 145px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  z-index: 1;
}

#headerLeafLogoM {
  width: 30px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  margin: 25px 0 0 23px;
}
#headerLeafLogoD {
  width: 30px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  margin: 25px 0 0 23px;
  display: none;
}

.headerCentralLogoCL {
  height: 7vw;
  max-height: 30px;
  width: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
}

#menuButtonCL {
  position: absolute;
  right: 0;
  top: 0;
  margin: 35px 25px 0 0;
  z-index: 4;
  overflow-y: hidden;
}

#menuButtonCL.buttonOpen div,
#menuButton.buttonClose div {
  transition: all ease 0.2s;
}

#menuButtonCL.buttonOpen div:nth-child(1) {
  transform: rotate(45deg) translateX(0px) translateY(2px);
  position: fixed;
}

#menuButtonCL.buttonOpen .lineCL {
  background-color: var(--white);
}

#menuButtonCL.buttonOpen:hover .lineCL,
#menuButton.buttonOpen:focus .lineCL {
  background-color: var(--white);
}

#menuButtonCL.buttonOpen div:nth-child(2) {
  opacity: 0;
  transition: none;
}

#menuButtonCL.buttonOpen div:nth-child(3) {
  transform: rotate(-45deg) translateX(5px) translateY(-7px);
  position: fixed;
}

.lineCL {
  width: 30px;
  height: 3px;
  margin-bottom: 7px;
  background-color: var(--white);
  border-radius: 5px;
  position: static;
}

#mobileNav {
  height: 100lvh;
  width: 100%;
  background-color: var(--black);
  position: absolute;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
  z-index: 3;
  /* padding-top: 15%; */
  margin-top: auto;
  margin-bottom: auto;
}

#mobileNav a,
#mobileNav p {
  color: var(--white);
  text-decoration: none;
  transition: all linear 0.2s;
}

.activeCL {
  transition: all linear 0.2s;
  border-bottom: var(--white) solid 3px;
}

#mobileNav a:hover,
#mobileNav a:focus {
  border-bottom: var(--green) solid 3px;
  padding-bottom: 1px;
}

#menuFF {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobileHidden {
  display: none;
}

.hoverFF {
  transition: all ease 0.3s;
}

#menuFF:hover .hoverFF {
  color: var(--green);
}

#mobileNav a.hoverCuisineLocative:hover,
#mobileNav a.hoverCuisineLocative:focus {
  border-bottom: var(--white) double 3px;
  padding-bottom: 1px;
}

#mobileNav.close {
  height: 100%;
  display: none;
}

#mobileNav ul li.sublist {
  padding-top: 20px;
}

#mobileNav ul li.sublist a {
  font-size: 16px;
}

#social {
  display: flex;
  padding-top: 75px;
}

#headerSocial_1 {
  margin-right: 40px;
  height: 40px;
}

#headerSocial_2 {
  height: 40px;
}

#headerSocial_1_bk,
#headerSocial_2_bk {
  display: none;
}

#headerSocial_1:hover,
#headerSocial_2:hover {
  cursor: pointer;
}

#social .iconNotUnderline {
  transition: none;
}
#social .iconNotUnderline:hover {
  border-bottom: solid 0px transparent;
}

@media screen and (max-height: 670px) {
  .fontDongle {
    font-family: "Dongle", sans-serif;
    font-size: 30px;
    line-height: 1.5rem;
  }

  .fontCookie {
    font-family: "Cookie", cursive;
    font-size: 28px;
    text-transform: none;
  }

  #mobileNav {
    padding-top: 4vh;
  }

  #mobileNav ul li.sublist {
    padding-top: 10px;
  }

  #mobileNav ul li.sublist a {
    font-size: 16px;
  }

  #social {
    display: flex;
    padding-top: 30px;
  }

  #headerSocial_1 {
    margin-right: 40px;
    height: 40px;
  }

  #headerSocial_2 {
    height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  #mobileNav {
    padding-top: 0%;
  }

  #mobileNav ul {
    padding-top: 10svh;
  }

  #mobileNav a {
    font-size: 20px;
  }

  .fontDongle {
    font-family: "Dongle", sans-serif;
    font-size: 30px;
    line-height: 1.5rem;
  }

  .fontCookie {
    font-family: "Cookie", cursive;
    font-size: 28px;
    text-transform: none;
  }

  .sublist {
    padding-top: 25px;
  }

  .sublist a {
    font-size: 16px;
  }

  #social {
    display: flex;
    padding-top: 75px;
  }
  #cuisineLocative a {
    font-size: 28px;
  }
  #headerSocial_1 {
    margin-right: 40px;
    height: 40px;
  }

  #headerSocial_2 {
    height: 40px;
  }
}

@media only screen and (min-width: 992px) {
  * {
    font-size: 14px;
  }

  .headerCentralLogoCL,
  .CLHiddenDesktop {
    display: none;
  }

  #headerWrapperCL {
    background-color: var(--white);
    height: 145px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  #mobileNav ul {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: normal;
    padding: 0;
    text-align: center;
    list-style: none;
    position: absolute;
    top: 5px;
    height: 50px;
  }

  #mobileNav ul li {
    padding-top: 20px;
    margin-right: 30px;
  }

  #menuFF {
    display: flex;
    flex-direction: row;
  }

  #mobileNav ul li.fontDongle {
    width: 110px;
    font-size: 27px;
    margin-top: -5px;
  }

  #mobileNav ul li.fontDongle:hover {
    cursor: pointer;
  }

  #sublistWrapper {
    position: absolute;
    display: flex;
    left: -10px;
    top: 65px;
    padding-left: 70px;
    width: auto;
    height: 60px;
    border-top: 2px solid var(--black);
    transition: all 0.2s ease 0.1s;
  }
  #sublistWrapper.close {
    opacity: 0;
    transition: all 0.1s linear 0s;
  }

  #mobileNav ul li.sublist {
    padding-left: 35px;
  }

  #mobileNav ul li.sublist:first-child {
    padding-left: 30px;
    margin-left: 20px;
    margin-right: -20px;
    padding-right: 40px;

    padding-top: 22px;
    border-top: white solid 2px;
  }

  #mobileNav ul li.sublist a {
    position: absolute;
    font-size: 14px;
  }

  #mobileNav ul li.sublist a:first-child {
    position: relative;
  }

  #cuisineLocative {
    width: 70px;
    padding-top: 5px;
    line-height: 1.5rem;
    margin-top: -10px;
  }

  .fontCookie {
    font-size: 25px;
  }

  #headerLeafLogoD {
    margin: 15px 0 0 50px;
    display: inline;
  }

  #headerLeafLogoM {
    display: none;
  }

  #mobileNav {
    background-color: transparent;
    color: var(--black);
  }
  #mobileNav a,
  #mobileNav p {
    color: var(--black);
    padding-top: 0;
  }

  #mobileNav.close,
  #mobileNav.open {
    height: 70px;
    width: 100%;
    display: flex;
  }

  #headerWrapperCL {
    height: 70px;
    transition: all ease 0.2s; /*80px*/
  }

  #headerWrapperCL.open {
    height: 130px; /*80px*/
  }
  .headerCentralLogo {
    display: none;
  }

  #menuButtonCL {
    display: none;
  }
  .mobileHidden {
    display: block;
    position: absolute;
    margin-left: 105px;
    margin-top: 15px;
  }

  #mobileNav a span.border-bottom {
    transition: all ease 0.1s;
  }

  #mobileNav a.hoverCuisineLocative:hover,
  #mobileNav a.hoverCuisineLocative:focus {
    border-bottom: none;
  }

  #mobileNav a:hover span.border-bottom,
  #mobileNav a:focus span.border-bottom {
    border-bottom: var(--black) double 3px;
  }

  #social {
    position: absolute;
    padding: 10px;
    justify-content: center;
    top: 10px;
    right: 35px;
  }

  #headerSocial_1_bk {
    margin-right: 30px;
    height: 30px;
  }

  #headerSocial_1_bk,
  #headerSocial_2_bk {
    display: flex;
    top: 0;
    height: 30px;
    margin-left: 0;
  }

  #headerSocial_1_bk:hover,
  #headerSocial_2_bk:hover {
    padding: 0;
  }

  #headerSocial_1,
  #headerSocial_2 {
    display: none;
  }

  #arrow_down {
    transition: all ease 0.2s;
  }

  #arrow_down.open {
    transform: rotateX(180deg);
  }

  .activeCL span {
    border-bottom: var(--black) solid 3px;
  }

  .activeCL:first-child {
    border: none;
  }
}
