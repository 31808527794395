
.bubble-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  font-family: sans-serif;
  font-size: 13px;
  color: #131313;

  &:hover {
    background: rgba(255, 255, 255, 0.75);
  }
}

/* Bubble Styles */

.bubbles {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 80vw;
  background-color: #ffffff00;
}

.bubble-container {
  position: absolute;
  bottom: 0;
  will-change: transform;
  animation: bubblerise 4s infinite ease-in;
  opacity: 0;
}

.bubble {
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border: 3px solid #1e1e1e;
  background: #1e1e1e02;
  will-change: transform;
  border-radius: 200px;
  animation: bubblewobble 0.4s infinite linear;
}

/* Keyframe Animations */

@keyframes bubblerise {
  0% {
    bottom: 0;
    opacity: 0;
  }
  5% {
    bottom: 0;
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    bottom: 100%;
    opacity: 0;
  }
}

/* @keyframes bubblewobble {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 2px;
  }
} */
